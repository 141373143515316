import React from 'react'
import 'twin.macro'

export default function FactDetail({data,page}){
  let finalData= data?.schema_json;  
  let content= {
      heading:'Fun fact about <span class="inline-block text-primary-900"> SEO </span>',
      sub_heading:"",
      description:"70% to 80%  of users focus exclusively on organic results and ignore paid listings. What’s more, some 28% of those searches convert,   resulting in a purchase.",
      img_src:"",
     ...finalData
} 
    return (
      <div tw="px-4 py-4 md:py-8 mx-auto md:px-24 lg:px-8 md:w-11/12">
        <div tw="p-8 rounded shadow-xl sm:p-16 border  bg-gradient-to-r from-primary-100 to-secondary-200">
          <div tw="flex flex-col items-center lg:flex-row">
            <div tw="mb-6 lg:mb-0 lg:w-2/6 lg:pr-5">
              <div tw="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none" dangerouslySetInnerHTML={{ __html: content.heading }} />
            </div>
            <div tw="lg:w-4/6">
              <div tw="mx-4 text-lg text-gray-700" dangerouslySetInnerHTML={{ __html: content.description }} />
            </div>
          </div>
        </div>
      </div>
    );
  };